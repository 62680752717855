// @flow
import type { AbstractComponent, Node } from "react";
import React, { useEffect, useState } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { selectors as userInfoSelectors } from "redux/modules/user_info";
import * as Sentry from "@sentry/react";

const selectors = {
  isLoggedIn: userInfoSelectors.is_logged_in,
};

const mapStateToProps = createStructuredSelector(selectors);

const REPLAY_RATE = 0.25;

type Props = {
  isLoggedIn: boolean,
  children?: Node,
};

const ReplayMonitoring = ({ isLoggedIn, children }: Props) => {
  const [replayStarted, setReplayStarted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      isLoggedIn &&
      !replayStarted &&
      location.pathname.match("/m/") &&
      Sentry.isInitialized()
    ) {
      setReplayStarted(true);
      if (Math.random() <= REPLAY_RATE) {
        const replay = Sentry.getReplay();
        console.log("Starting replay");
        replay.start();
      }
    }
  }, [isLoggedIn, location, replayStarted]);

  return children;
};

ReplayMonitoring.defaultProps = {
  children: null,
};

export default (connect(
  mapStateToProps,
  {},
)(ReplayMonitoring): AbstractComponent<Props>);
