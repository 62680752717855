import defaultTo from "lodash/defaultTo";
import isString from "lodash/isString";
import map from "lodash/map";
import toString from "lodash/toString";
import {
  SUBSCRIPTION_TYPE_BASIC,
  SUBSCRIPTION_TYPE_PREMIUM,
} from "helpers/constants";

export function clean_path_base(data) {
  const title = defaultTo(data.title, defaultTo(data.name, ""));
  const description = defaultTo(data.description, "");
  return {
    sequence: toString(defaultTo(data.sequence, "")),
    id: toString(data.id),
    title,
    description,
    slug: defaultTo(data.slug, ""),
    progress: data.progress || 0,
    meta_title: defaultTo(data.meta_title, ""),
    meta_description: defaultTo(data.meta_description, ""),
  };
}
function mapLessonTypes(type) {
  if (type === "jupyter_project") {
    return "project";
  }
  return defaultTo(type, "");
}
export function fix_upgrade_plan({
  upgrade_suggestion,
  is_behind_paywall,
  premium,
}) {
  if (upgrade_suggestion == null) {
    if (is_behind_paywall) {
      return premium ? SUBSCRIPTION_TYPE_PREMIUM : SUBSCRIPTION_TYPE_BASIC; // legacy
    }
    return "";
  }
  if (upgrade_suggestion === true) return SUBSCRIPTION_TYPE_PREMIUM;
  return upgrade_suggestion;
}
export function clean_upgrade_suggestion(info) {
  return fix_upgrade_plan(info);
}
export function fix_learn(learn = []) {
  return learn.map(value =>
    isString(value) ? { text: value, icon: null } : value,
  );
}
export const cleanLesson = (lesson, courseSequence = "") => ({
  ...clean_path_base(lesson),
  type: mapLessonTypes(lesson.type),
  is_new: defaultTo(lesson.is_new, defaultTo(lesson.new, false)),
  is_behind_paywall: defaultTo(lesson.is_behind_paywall, false),
  is_behind_paywall_current_path: defaultTo(
    lesson.is_behind_paywall_current_path,
    false,
  ),
  upgrade_suggestion: clean_upgrade_suggestion(lesson),
  freeLessonsLeft: defaultTo(lesson.freeLessonsLeft, 1),
  learn: fix_learn(defaultTo(lesson.learn, lesson.learn_points)),
  interesting_data: defaultTo(lesson.interesting_data, []),
  in_paths: defaultTo(lesson.in_paths, []),
  in_course: defaultTo(lesson.in_course, {}),
  duration: lesson.duration,
  rating: lesson.rating,
  rating_count: lesson.duration,
  skill_level: lesson.skill_level,
  time_completed: lesson.time_completed,
  takeaways: defaultTo(lesson.takeaways, ""),
  technology: defaultTo(lesson.technology, ""),
  has_takeaways: defaultTo(lesson.has_takeaways, false),
  has_chat_controls: defaultTo(lesson.has_chat_controls, false),
  course_sequence: courseSequence
    ? toString(courseSequence)
    : toString(lesson.course_sequence),
  path_sequence: toString(lesson.path_sequence),
  path_version: toString(lesson.path_version),
  screen_count: defaultTo(lesson.screen_count, 0),
  isAssessment: defaultTo(lesson.type === "assessment", false),
  is_next_gen: defaultTo(lesson.is_next_gen, false),
  has_paywall_override: defaultTo(lesson.has_paywall_override, false),
  is_first_with_course_progress: defaultTo(
    lesson.is_first_with_course_progress,
    false,
  ),
  is_first_with_path_progress: defaultTo(
    lesson.is_first_with_path_progress,
    false,
  ),
});
function cleanLessons(lessons, courseSequence = "") {
  return map(lessons, lesson => cleanLesson(lesson, courseSequence));
}
export default cleanLessons;
