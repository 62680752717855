import Cookies from "js-cookie";
import defaultTo from "lodash/defaultTo";
import map from "lodash/map";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import intersection from "lodash/intersection";
import { gql } from "@apollo/client";
import { print } from "graphql/language/printer";
import { createHash } from "crypto";

// We use 90 seconds because our more data-intensive missions take longer than 30s to process
// TODO: work towards a 30-second timout target
export const DEFAULT_TIMEOUT = 90000;

const OK = 200;
const ERRORS = 300;
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const NOT_FOUND = 404;

// Map object to store ongoing requests' controllers
const ongoingRequests = new Map();

function generateRequestHash(url, post_obj) {
  // CLI answer-checking requests sends `data`, others don't.
  // This field is not helpful in identifying the request
  if (post_obj?.payload?.data) {
    delete post_obj.payload.data;
  }

  const hash = createHash("sha256");
  hash.update(JSON.stringify({ url, post_obj }));
  return hash.digest("hex");
}

export function checkStatus(response = {}) {
  if (response.status >= OK && response.status < ERRORS) {
    return response;
  }

  if (response.status === UNAUTHORIZED || response.status === FORBIDDEN) {
    return response;
  }

  /**
   * Use normal object instead of error to reduce noise in our logs
   *
   * TODO: Exception passing for simple control flow is a bad code smell
   *
   */
  const throwable = {
    message: response.statusText,
    response,
    response_as_string: JSON.stringify(response),
  };
  throw throwable;
}

export function params_to_url(params) {
  const convert = (value, key) => `${key}=${window.encodeURIComponent(value)}`;
  return `?${map(params, convert).join("&")}`;
}

export function ql_escape(text) {
  return text.replace(/"/g, '\\"');
}
// https://stackoverflow.com/questions/9577930/regular-expression-to-select-all-whitespace-that-isnt-in-quotes
const replace_spaces = /\s+(?=((\\[\\"]|[^\\"])*"(\\[\\"]|[^\\"])*")*(\\[\\"]|[^\\"])*$)/g;

export function cleanQuery(query) {
  let cleanedQuery = query;
  if (
    !cleanedQuery.includes("IntrospectionQuery") &&
    !cleanedQuery.includes("SendReferralInvite") &&
    !cleanedQuery.includes("SkipOnboarding") &&
    !cleanedQuery.includes("CombinedQuery")
  ) {
    // do not format these types
    cleanedQuery = cleanedQuery.replace(/{\n/g, "{");
    cleanedQuery = cleanedQuery.replace(/}\n/g, "}");
    cleanedQuery = cleanedQuery.replace(/\n/g, ",");
    cleanedQuery = cleanedQuery.replace(/\\"/g, '\\"');
    cleanedQuery = cleanedQuery.replace(replace_spaces, "");
  }

  return cleanedQuery;
}

export function dq_graphql(query, options = {}) {
  const postObj = { query: cleanQuery(query) };
  const optionsObj = { ...options, post_obj: postObj };
  const url = defaultTo(options.url, "/api/graphql/");
  return dq_fetch(url, optionsObj).then(response => {
    if (response?.errors) {
      return Promise.reject(response.errors);
    }
    return response;
  });
}

/**
 * Wraps a list of queries (promises) and returns promise with
 * combined data once all queries are resolved
 *
 * Works best if data fields are not overlapping or have the same data for overlapping keys
 *
 * In case when a key has an array as a value, the arrays will be merged using index only
 * Thus it's important to be sure that data will arrive in the same order from API
 *
 * There are no safety checks added to avoid this as it will over-complicate the usage and
 * produce a deep-comparison of key-values between objects that can hinder the performance.
 * Such safety check also makes function less-generic.
 *
 * @param queryList [{Promise<any>}]
 * @returns {Promise<any>}
 */
export function dq_graphql_all(queryList) {
  const mergedQuery = [];

  queryList.forEach(currentQuery => {
    const cleanedQuery = cleanQuery(currentQuery);

    const queryObj = gql`
      ${cleanedQuery}
    `;
    if (mergedQuery.length) {
      const queryField =
        queryObj.definitions[0].selectionSet?.selections[0]?.name?.value;
      const mergedQueryObj = gql`
        ${mergedQuery.join("\n")}
      `;
      const duplicateFieldIndex = mergedQueryObj?.definitions?.findIndex(
        definition =>
          definition.selectionSet?.selections[0]?.name?.value === queryField,
      );
      if (duplicateFieldIndex !== -1) {
        // Means there's another query that uses the same field for selection
        const duplicateDefinitionObj = gql`
          ${mergedQuery[duplicateFieldIndex]}
        `;
        // Extract list of arguments for both queries
        const queryArgs = queryObj.definitions[0].selectionSet?.selections[0]?.arguments.map(
          arg => arg.name.value,
        );
        const dupeQueryArgs = duplicateDefinitionObj.definitions[0].selectionSet?.selections[0]?.arguments.map(
          arg => arg.name.value,
        );

        // Check if arguments have an intersection. If there's a shared key, we would want to merge two queries
        if (intersection(queryArgs, dupeQueryArgs).length) {
          duplicateDefinitionObj.definitions[0].selectionSet?.selections[0]?.selectionSet.selections.forEach(
            selection => {
              if (
                !queryObj.definitions[0].selectionSet?.selections[0]?.selectionSet.selections.filter(
                  qSelection => qSelection.name.value === selection.name.value,
                ).length
              )
                queryObj.definitions[0].selectionSet?.selections[0]?.selectionSet.selections.push(
                  selection,
                );
            },
          );
          mergedQuery[duplicateFieldIndex] = print(queryObj);
        }
      } else mergedQuery.push(cleanedQuery);
    } else mergedQuery.push(cleanedQuery);
  });

  const queryParts = [
    "query CombinedQuery {",
    ...map(mergedQuery, currentQuery => {
      const cleanedQuery = cleanQuery(currentQuery);
      const unwrappedQuery = cleanedQuery
        .replace(/^(\s|\n)*{/, "")
        .replace(/}(\s|\n)*$/, "");

      return `${unwrappedQuery}`;
    }),
    "}",
  ];

  const query = queryParts.join("\n");
  return dq_graphql(query);
}

/**
 * Wraps any promise and rejects automatically if the promise does not
 * resolve within the stipulated millisecond interval.
 *
 * @see https://stackoverflow.com/questions/46946380/fetch-api-request-timeout
 *
 * @param {number} ms
 * @param {Promise<any>} promise
 * @returns {Promise<any>}
 */
export function timeoutPromise(ms, promise) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error(`Timeout after ${ms} milliseconds`));
    }, ms);
    promise.then(
      res => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      err => {
        clearTimeout(timeoutId);
        reject(err);
      },
    );
  });
}

/**
 * Wrapper to add a timeout parameter to fetch
 *
 * @param url
 * @param options
 * @param timeout
 */
export function dq_fetchTimeout(url, options = {}, timeout = DEFAULT_TIMEOUT) {
  return timeoutPromise(timeout, dq_fetch(url, options));
}

export default function dq_fetch(url, options = {}) {
  const {
    post_obj,
    get_params,
    redirect_url,
    type,
    return_raw,
    authorization,
    csrf,
    headers,
    resolveErr,
    number_of_retries,
    abortPrevious,
  } = options;

  let abortSignal;

  if (abortPrevious) {
    const requestHash = generateRequestHash(url, post_obj);
    if (ongoingRequests.has(requestHash)) {
      const controller = ongoingRequests.get(requestHash);
      controller.abort();
      ongoingRequests.delete(requestHash);
    }
    const controller = new AbortController();
    abortSignal = controller.signal;
    ongoingRequests.set(requestHash, controller);
  }

  let queryUrl = url;
  if (get_params) queryUrl += params_to_url(get_params);
  // the following is useful for testing
  const replace_url = options.replace_url || (r => window.location.replace(r));

  const handleError = (error, attempt, resolve, reject) => {
    if (attempt <= number_of_retries) {
      attemptFetch(resolve, reject, attempt + 1);
    } else {
      if (document.visibilityState === "visible") {
        reject(error);
      } else {
        resolve();
      }
    }
  };

  const attemptFetch = (resolve, reject, attempt = 1) => {
    const fetch_options = {
      credentials: "same-origin",
      headers: {},
    };

    if (abortSignal) {
      fetch_options.signal = abortSignal;
    }

    if (headers) {
      fetch_options.headers = headers;
    }

    const outer_token = Cookies.getJSON("token");
    if (authorization) {
      fetch_options.headers.authorization = authorization;
    } else if (outer_token && outer_token.token) {
      fetch_options.headers.authorization = `Token ${outer_token.token}`;
    }

    if (post_obj) {
      fetch_options.method = "POST";
      fetch_options.body = JSON.stringify(post_obj);
      fetch_options.headers["Content-Type"] = "application/json";
    }
    if (type) {
      fetch_options.method = type.toUpperCase();
    }

    const csrf_token = Cookies.get("csrftoken");
    if (csrf && csrf_token) {
      fetch_options.headers["X-CSRFToken"] = csrf_token;
    }

    window
      .fetch(queryUrl, fetch_options)
      .then(checkStatus)
      .then(
        response => {
          if (return_raw) {
            resolve(response);
            return;
          }
          if (
            response.status !== UNAUTHORIZED &&
            response.status !== FORBIDDEN
          ) {
            response
              .json()
              .then(resolve)
              .catch(() => resolve({}));
          } else {
            if (response.status !== OK && resolveErr) reject(response);
            // need for calls like reset_progress
            response
              .json()
              .then(data =>
                resolve({
                  unauthorized: true,
                  mfa_required: data.mfa_required,
                  ...data.data,
                }),
              )
              .catch(reject);
          }
        },
        error => {
          handleError(error, attempt, resolve, reject);
          if (error.response == null) {
            reject(error);
            return;
          }

          const not_in_array = -1;
          const redirect =
            [NOT_FOUND].indexOf(error.response.status) !== not_in_array;

          if (redirect_url && redirect) {
            replace_url(redirect_url);
          }
          reject(error);
        },
      )
      .catch(error => {
        handleError(error, attempt, resolve, reject);
      });
  };

  return new Promise((resolve, reject) => {
    attemptFetch(resolve, reject);
  });
}

export const fetchStream = async (url, payload, setData, setError, setDone) => {
  await fetchEventSource(url, {
    method: "POST",
    headers: {
      Accept: "text/event-stream",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    async onopen(res) {
      if (res.status >= 400 && res.status < 500 && res.status !== 429) {
        console.log("Client-side error ", res);
        setError(
          "Connection error or there's an issue with our system, please try again.",
        );
      } else if (res.status === 429) {
        console.log("Rate limit error ", res);
        setError(
          "You've made too many requests recently. Please try again later.",
        );
      }
    },
    onmessage(event) {
      if (setData) setData(event);
    },
    onclose() {
      if (setDone) setDone();
    },
    onerror(err) {
      console.log("There was an error from server", err);
      if (setError) setError("Server error, please try again.");
    },
  });
};
