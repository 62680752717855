/* eslint-disable import/prefer-default-export */
/**
 * Value object to facilitate working with promotions.
 */
export class Promotion {
  /**
   * Label for easy promo identification
   *
   * @type {string}
   */
  label = "default";

  /**
   * Promo code
   *
   * @type {string}
   */
  code = "";

  /**
   * Callout text
   *
   * @type {string}
   */
  callout = "";

  /**
   * Start date for promotion
   *
   * @type {string}
   */
  start = "";

  /**
   * Start date for promotion as a date
   *
   * @type {Date}
   */
  startDate = new Date();

  /**
   * End date for promo duration
   *
   * @type {string}
   */
  end = "";

  /**
   * End date for promo duration as a date
   *
   *
   * @type {Date}
   */
  endDate = new Date();

  /**
   * End date for promotion extension
   *
   * @type {string}
   */
  extended = "";

  /**
   * End date for promotion extension as a date
   *
   * @type {Date}
   */
  extendedDate = new Date();

  /**
   * Test to be used in Call to Action buttons
   *
   *
   * @type {string}
   */
  CTAText = "On Sale";

  /**
   *
   * Enables the countdown timer on the app.
   *
   * True by default
   *
   * @type {boolean}
   */
  showCountdownTimer = true;

  /**
   *
   * Text to show as the promotion's tagline.
   *
   * This can be customized in the event we want
   * to anchor a promotion to a particular holiday.
   *
   * @type {string}
   */
  tagLine = "";

  /**
   *
   * List of promo features
   *
   * This defines whether we need to include particular features (lifetime plan, career services)
   * on the payment page.
   *
   * @type [string]
   */
  features = "";

  /**
   *
   * Creates a new promotion object
   *
   * @param promo {{label: string, code: string, start: string, end: string, cta_text: string, tagline: string, show_countdown_timer: boolean}}
   */
  constructor(promo) {
    this.fromObject(promo);
  }

  /**
   * Populates class properties from an object.
   *
   * This is useful for instantiating new promo objects
   * from service calls.
   *
   * @param promo {{label: string, code: string, start: string, end: string, cta_text: string, tagline: string, show_countdown_timer: boolean}}
   */
  fromObject(promo) {
    this.label = promo.label;
    this.code = promo.code;
    this.start = promo.start;
    this.startDate = new Date(promo.start);
    const endDate = promo.personalized_end ? promo.personalized_end : promo.end;
    this.end = endDate;
    this.endDate = new Date(endDate);
    this.page_tagline = promo.page_tagline || promo.tagline;
    this.features = promo.features || {};
    this.default = promo.default;
    this.showBanner = promo.show_banner;

    if (promo.cta_text) {
      this.CTAText = promo.cta_text;
    }

    if (promo.callout) {
      this.callout = promo.callout.split("\n") || [];
    }

    if (typeof promo.show_countdown_timer !== "undefined") {
      this.showCountdownTimer = promo.show_countdown_timer;
    }

    if (promo.tagline) {
      this.tagLine = promo.tagline;
    }
  }

  /**
   *
   * Determines if a promotion is active
   * for a supplied date.
   *
   * @param {Date} now
   * @returns {boolean}
   */
  isActiveForDate(now) {
    if (
      now.getTime() >= this.startDate.getTime() &&
      now.getTime() < this.endDate.getTime()
    ) {
      return true;
    }

    return false;
  }
}
