// @flow
import type { Node } from "react";
import React, { useCallback } from "react";
import cn from "classnames";
import { IoCheckmarkSharp, IoCopySharp } from "react-icons/io5";

type Props = {
  /**
   * Text of the label element
   */
  label?: string,
  /**
   * Adds an additional text below the input
   */
  description?: Node | string,
  /**
   * Add additional style classes for label element
   */
  labelClassName?: string,
  /**
   * Add additional style classes for input element
   */
  inputClassName?: string,
  /**
   * Add additional style classes
   */
  className?: string,
  /**
   * ID of the input element, same value set for name input property
   */
  id: string,
  /**
   * ID of the input element, same value set for name input property
   */
  value: any,
  /**
   * Field type to render (email, number, text)
   */
  type?: string,
  /**
   * Type of control to render (input, textarea)
   */
  control?: string,
  /**
   * Add a leading icon
   */
  startIcon?: Object,
  /**
   * Pass reference to input element
   */
  innerRef?: Object,
  /**
   * Enable styles variant
   */
  variant?: boolean,
  /**
   * Enable copy button
   */
  copy?: boolean,
  /**
   * Is readonly
   */
  readonly?: boolean,
  /**
   * Is disabled
   */
  disabled?: boolean,
};

const DqInput = ({
  label = "",
  description = "",
  labelClassName = "",
  inputClassName = "",
  className = "",
  id,
  value,
  control = "input",
  type = "text",
  startIcon,
  innerRef = null,
  variant = false,
  disabled = false,
  readonly = false,
  copy = false,
  ...others
}: Props): Node => {
  const Control = control;
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = useCallback(() => {
    const data = [
      // $FlowFixMe
      new ClipboardItem({
        "text/plain": Promise.resolve(
          new Blob([value], { type: "text/plain" }),
        ),
      }),
    ];

    // $FlowFixMe
    navigator.clipboard.write(data).then(
      () => {
        // Let know user that the URL has been copied,
        // and after 1 seconds update button text.
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 750);
      },
      e => {
        console.error(e);
      },
    );
  }, []);

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className={cn(
            "dq-inline-flex dq-items-center",
            {
              "dq-font-bold": variant,
            },
            labelClassName,
          )}
        >
          {label}
        </label>
      )}
      <div className="dq-relative">
        {startIcon && (
          <div
            className={cn(
              "dq-absolute dq-inset-y-0 dq-left-0 dq-pl-3 dq-flex dq-items-center",
              {
                "dq-pointer-events-none": !startIcon.props.onClick,
              },
            )}
          >
            {startIcon}
          </div>
        )}
        <Control
          {...others}
          type={type}
          name={id}
          id={id}
          ref={innerRef}
          disabled={disabled}
          readOnly={readonly}
          value={value}
          className={cn(
            "dq-w-full dq-transition-all dq-outline-none",
            {
              "dq-pl-10": startIcon,
              "dq-pr-10": copy,
              "dq-cursor-not-allowed": disabled && readonly,
              "dq-px-4 dq-py-2 dq-bg-gray-50 dq-border-gray-100 dq-border-2 focus:dq-border-blue": !variant,
              "dq-px-3 dq-py-2 dq-bg-gray-100 dq-placeholder-gray-800/50 focus:dq-bg-white focus:dq-shadow focus:dq-ring-1 focus:dq-ring-blue": variant,
            },
            inputClassName,
          )}
        />
        {copy && (
          <div
            className={cn(
              "dq-absolute dq-inset-y-0 dq-right-0 dq-px-3 dq-py-2 dq-flex dq-items-center",
              "dq-cursor-pointer dq-text-gray-400 dq-z-10",
            )}
          >
            {copied ? (
              <IoCheckmarkSharp />
            ) : (
              <IoCopySharp onClick={copyToClipboard} />
            )}
          </div>
        )}
        {description && (
          <p
            id={`${id}-description`}
            className="sm:dq-text-sm dq-text-gray-400"
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

DqInput.defaultProps = {
  label: "",
  description: "",
  labelClassName: "",
  inputClassName: "",
  className: "",
  value: "",
  type: "text",
  control: "input",
  startIcon: null,
  innerRef: null,
  variant: false,
  disabled: false,
  readonly: false,
  copy: false,
};

export default DqInput;
