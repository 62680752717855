// @flow
import type { Node } from "react";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createStructuredSelector } from "reselect";

import { selectors as user_selectors } from "redux/modules/user_info";
import {
  LEARNING_PATH_SLUG_KEY,
  LEARNING_PATH_VERSION_KEY,
} from "helpers/constants";

type OwnProps = {|
  children: Node,
|};

type Props = {|
  ...OwnProps,

  // Router Props
  location: {
    search: any,
    pathname: string,
  },

  // User Info
  is_new_user: boolean,
|};

type TargetUrlsType = {|
  targetPath: string,
  targetVersion: string,
  targetCourse: string,
  targetLesson: string,
  targetUrl: string,
  postSignupUrl: string,
  postLoginUrl: string,
|};

const selectors = {
  is_new_user: user_selectors.is_new_user,
};

const mapStateToProps = createStructuredSelector(selectors);

/**
 * Wrapped component providing auth components with jumplink and post-auth target urls
 * @param WrappedComponent              receives target url props
 * @returns
 */
const withJumplinks = (WrappedComponent: Element): Node => {
  const AuthJumplinks = (props: Props): Node => {
    const DEFAULT_SIGNUP_TARGET = React.useMemo(() => "/onboarding", []);
    const DEFAULT_LOGIN_TARGET = React.useMemo(() => "/dashboard", []);
    const DEFAULT_JUMPLINK_TARGET = React.useMemo(() => "/learning/path", []);
    const DEFAULT_JUMPLINK_COURSE_TARGET = React.useMemo(
      () => "/learning/course",
      [],
    );

    // Destructure used props
    const { location, ...restProps } = props;

    /**
     * Get expected query parameters in memoized form
     */
    const queryParams = React.useMemo(() => {
      const params = new URLSearchParams(location.search);
      const pathSlugQuery = params.get("path") || "";
      const pathVersionQuery = params.get("version") || "";
      const courseSlugQuery = params.get("course") || "";
      const lessonSlugQuery =
        params.get("mission") || params.get("lesson") || "";
      const targetUrlQuery = params.get("target-url") || "";
      const skipSource = params.get("source") || "";

      return {
        pathSlugQuery,
        pathVersionQuery,
        courseSlugQuery,
        lessonSlugQuery,
        targetUrlQuery,
        skipSource,
      };
    }, [location.search]);

    /**
     * Structure target slugs from query arguments into object
     */
    const targetSlugs = React.useMemo(
      () => ({
        path: queryParams.pathSlugQuery,
        version: queryParams.pathVersionQuery,
        course: queryParams.courseSlugQuery,
        lesson: queryParams.lessonSlugQuery,
      }),
      [queryParams],
    );

    /**
     * Skip source memoized value
     */
    const skipSource = React.useMemo(() => queryParams.skipSource, [
      queryParams,
    ]);

    /**
     * Onboarding skipping state
     */
    const [
      shouldSkipOnboardingForNow,
      setShouldSkipOnboardingForNow,
    ] = React.useState(false);

    const getJumplinkTargetUrls = React.useCallback(() => {
      const {
        pathSlugQuery,
        pathVersionQuery,
        courseSlugQuery,
        lessonSlugQuery,
      } = queryParams;
      const targetVersion = pathVersionQuery;

      const targetPath = pathSlugQuery ? `/path/${pathSlugQuery}` : "";
      const targetCourse = courseSlugQuery ? `/course/${courseSlugQuery}` : "";
      const targetLesson = lessonSlugQuery ? `/m/${lessonSlugQuery}` : "";

      return {
        targetPath,
        targetVersion,
        targetCourse,
        targetLesson,
      };
    }, [queryParams]);

    const getPostLoginUrl = React.useCallback(
      (targetPath, targetCourse, targetLesson, targetUrl) => {
        if (targetPath || targetCourse || targetLesson) {
          window?.localStorage?.removeItem(LEARNING_PATH_SLUG_KEY);
          window?.localStorage?.removeItem(LEARNING_PATH_VERSION_KEY);
        }

        if (targetLesson) return targetLesson;
        if (targetCourse) return DEFAULT_JUMPLINK_COURSE_TARGET;
        if (targetPath) return DEFAULT_JUMPLINK_TARGET;
        if (targetUrl) return targetUrl;
        return DEFAULT_LOGIN_TARGET;
      },
      [
        DEFAULT_JUMPLINK_COURSE_TARGET,
        DEFAULT_JUMPLINK_TARGET,
        DEFAULT_LOGIN_TARGET,
      ],
    );

    const getPostSignupUrl = React.useCallback(
      (targetPath, targetCourse, targetLesson, targetUrl) => {
        if (targetPath || targetCourse || targetLesson) {
          window?.localStorage?.removeItem(LEARNING_PATH_SLUG_KEY);
          window?.localStorage?.removeItem(LEARNING_PATH_VERSION_KEY);
        }

        if (targetLesson) return targetLesson;
        if (targetCourse) return DEFAULT_JUMPLINK_COURSE_TARGET;
        if (targetPath) return DEFAULT_JUMPLINK_TARGET;
        if (targetUrl) return targetUrl;
        return DEFAULT_SIGNUP_TARGET;
      },
      [
        DEFAULT_JUMPLINK_COURSE_TARGET,
        DEFAULT_JUMPLINK_TARGET,
        DEFAULT_SIGNUP_TARGET,
      ],
    );

    /**
     * Keep state of target urls
     */
    const [targetUrls, setTargetUrls] = React.useState<TargetUrlsType>({
      targetPath: "",
      targetVersion: "",
      targetCourse: "",
      targetLesson: "",
      targetUrl: "",
      postSignupUrl: "",
      postLoginUrl: "",
    });

    /**
     * Update target urls and onboarding skipping status
     */
    React.useEffect(() => {
      const { targetUrlQuery } = queryParams;
      const { pathname } = props.location;

      const {
        targetPath,
        targetVersion,
        targetCourse,
        targetLesson,
      } = getJumplinkTargetUrls();

      // when user signs up with target-url being either /payment or with
      // a path/course/lesson jumplink
      // we should let them finish the payment/subscription and show the onboarding after that
      let shouldSkipOnboarding =
        !!targetLesson ||
        !!targetCourse ||
        !!targetPath ||
        targetUrlQuery.includes("payment");

      let postSignupUrl = getPostSignupUrl(
        targetPath,
        targetCourse,
        targetLesson,
        targetUrlQuery,
      );
      let postLoginUrl = getPostLoginUrl(
        targetPath,
        targetCourse,
        targetLesson,
        targetUrlQuery,
      );
      if (pathname.startsWith("/m/")) {
        shouldSkipOnboarding = true;
        postSignupUrl = pathname;
        postLoginUrl = pathname;
      }

      // Do this first to avoid triggering target url effect without updated onboarding
      setShouldSkipOnboardingForNow(shouldSkipOnboarding);

      setTargetUrls({
        targetPath,
        targetVersion,
        targetCourse,
        targetLesson,
        targetUrl: targetUrlQuery,
        postLoginUrl,
        postSignupUrl,
      });
    }, [
      getJumplinkTargetUrls,
      getPostLoginUrl,
      getPostSignupUrl,
      props.location,
      queryParams,
      setTargetUrls,
    ]);

    return (
      // $FlowFixMe
      <WrappedComponent
        targetUrls={targetUrls}
        targetSlugs={targetSlugs}
        shouldSkipOnboardingForNow={shouldSkipOnboardingForNow}
        skipSource={skipSource}
        {...restProps}
      />
    );
  };

  return withRouter(connect(mapStateToProps)(AuthJumplinks));
};

export default withJumplinks;
