import React, { lazy } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import lazyRetry from "helpers/lazyRetry";
import DqLoading from "components/common/feedback/DqLoading";

import OnboardingView from "views/OnboardingView";

import page_not_found from "views/PageNotFoundView";
import DiscourseSSOView from "views/DiscourseSSOView";

import PaymentSuccess from "views/PaymentView/PaymentSuccess";
import GiftClaimView from "views/GiftClaimView/GiftClaimView";
import { window_location } from "helpers/window_location";
import { setRefersionTracking } from "helpers/refersion";
import RedirectWithParams from "./RedirectWithParams";

import { userIsAuthenticated } from "./helpers";

const DashboardView = lazy(() =>
  lazyRetry(() => import("views/DashboardView"), "DashboardView"),
);
const LearningPathView = lazy(() =>
  lazyRetry(() => import("views/LearningPathView"), "LearningPathView"),
);
const PracticeProblemsView = lazy(() =>
  lazyRetry(() => import("views/PracticeProblemsView"), "PracticeProblemsView"),
);
const ProjectHubView = lazy(() =>
  lazyRetry(() => import("views/ProjectHubView"), "ProjectHubView"),
);
const CourseView = lazy(() =>
  lazyRetry(() => import("views/CourseView"), "CourseView"),
);
const AccountView = lazy(() =>
  lazyRetry(() => import("views/AccountView"), "AccountView"),
);
const PaymentView = lazy(() =>
  lazyRetry(() => import("views/PaymentView"), "PaymentView"),
);
const GiftSubscriptionView = lazy(() =>
  lazyRetry(() => import("views/GiftSubscriptionView"), "GiftSubscriptionView"),
);
const CurrentPlanView = lazy(() =>
  lazyRetry(() => import("views/CurrentPlanView"), "CurrentPlanView"),
);
const ProfileView = lazy(() =>
  lazyRetry(() => import("views/ProfileView"), "ProfileView"),
);
const ReferralProgramView = lazy(() =>
  lazyRetry(() => import("views/ReferralProgramView"), "ReferralProgramView"),
);

const waitForRefersion = start => {
  // eslint-disable-next-line no-underscore-dangle
  if (window._rfsn_ready) {
    setRefersionTracking();
    window_location.redirect_to_marketing_site();
  } else if (Date.now() - start >= 3000) {
    window_location.redirect_to_marketing_site();
  } else {
    setTimeout(waitForRefersion, 10);
  }
};

const PathMarketingSiteRedirect = props => {
  const slugParam = props.match.params.path_slug;

  /**
   * This map of learning app path slugs to marketing site slugs
   * temporarily overrides slug associations. This only includes
   * slugs which do not directly translate to marketing site slugs.
   */
  const slugToMarketingSlugMap = {
    "sql-fundamentals": "sql-skills",
    "python-basics-data-analysis-skill": "python-basics-for-data-analysis",
    "r-basics-data-analysis-skill": "r-basics-for-data-analysis",
    "data-analysis-visualization-python-skill":
      "data-analysis-and-visualization-with-python",
    "data-visualization-r-skill": "data-visualization-with-r",
    "apis-web-scraping-python-skill": "apis-and-web-scraping-with-python",
    "apis-web-scraping-r-skill": "apis-and-web-scraping-with-r",
    "machine-learning-introduction-python-skill":
      "machine-learning-intro-with-python",
    "machine-learning-intermediate-python-skill":
      "machine-learning-intermediate-with-python",
    "probability-statistics-python-skill":
      "probability-and-statistics-with-python",
    "probability-statistics-with-r-skill": "probability-and-statistics-with-r",
  };

  /**
   * Generates a marketing slug by removing the trailing "-skill"
   * @param {string} slug
   * @returns {string} marketing slug
   */
  const generateMarketingSlug = slug =>
    slug.split("-skill")[0].split("-track")[0];

  // Get slug and redirect to path page on marketing site
  const mappedSlug = slugToMarketingSlugMap[slugParam];
  const generatedSlug = generateMarketingSlug(slugParam);
  const slug = mappedSlug || generatedSlug || slugParam;
  window_location.redirect_to_marketing_site(`/path/${slug}`);
  return null;
};

const NonLoadingRoutes = () => (
  <Switch>
    <RedirectWithParams
      from="/user-story/:story_name"
      to="/stories/:story_name"
    />

    <Route
      path="/stories"
      component={() => {
        window_location.redirect_to_marketing_site("/stories");
        return null;
      }}
    />

    <Route
      path="/learn"
      component={() => {
        waitForRefersion(Date.now());
        return <DqLoading fullScreen />;
      }}
    />

    <RedirectWithParams from="/onboarding/start/:step+" to="/onboarding" />
    <Route path="/onboarding">
      <Switch>
        <Route
          exact
          path="/onboarding/"
          component={userIsAuthenticated(OnboardingView)}
        />
        <Route
          path="/onboarding/:step+"
          component={userIsAuthenticated(OnboardingView)}
        />
      </Switch>
    </Route>

    <Route
      path="/why-dataquest"
      component={() => {
        window_location.redirect_to_marketing_site("/why-dataquest");
        return null;
      }}
    />

    <Redirect path="/plus" to="/payment" />
    <Route path="/gift-subscription" component={GiftSubscriptionView} />
    <Route path="/payment" component={userIsAuthenticated(PaymentView)} />
    <Route path="/welcome-back" component={userIsAuthenticated(PaymentView)} />
    <Route path="/upgrade" component={userIsAuthenticated(PaymentView)} />
    <Route path="/success" component={PaymentSuccess} />
    <Route
      path="/chat"
      component={() => {
        window_location.redirect("https://community.dataquest.io");
        return null;
      }}
    />
    <Route path="/sso" component={userIsAuthenticated(DiscourseSSOView)} />

    <Redirect path="/directory" to="/catalog" />
    <Route path="/projects">
      <Switch>
        <Route
          path="/projects/:project_type?"
          component={userIsAuthenticated(ProjectHubView)}
        />
      </Switch>
    </Route>
    <Route
      path="/privacy"
      component={() => {
        window_location.redirect_to_marketing_site("/privacy");
        return null;
      }}
    />
    <Route
      path="/terms"
      component={() => {
        window_location.redirect_to_marketing_site("/terms");
        return null;
      }}
    />

    <Route
      path="/why-dataquest"
      component={() => {
        window_location.redirect_to_marketing_site("/about-us");
        return null;
      }}
    />

    <Route path="/path">
      <Switch>
        <Route path="/path/:path_slug" component={PathMarketingSiteRedirect} />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </Route>
    <Route path="/course">
      <Switch>
        <Route
          path="/course/:course_slug"
          component={userIsAuthenticated(CourseView)}
        />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </Route>
    <Route
      path="/gift/claim/:gift_id"
      component={userIsAuthenticated(GiftClaimView)}
    />
    <Redirect from="/path-step" to="/catalog" />
    <Redirect from="/subject" to="/catalog" />
    <Redirect from="/today" to="/dashboard" />
    <Route
      path="/dashbaord"
      component={({ location }) => (
        <Redirect
          to={{
            ...location,
            pathname: location.pathname.replace(/dashbaord/, "dashboard"),
          }}
        />
      )}
    />
    <Route path="/dashboard" component={userIsAuthenticated(DashboardView)} />
    <Redirect from="/learning-path" to="/learning/path" />
    <Route
      path="/learning/:contentType/:slug?/v:version?"
      component={userIsAuthenticated(LearningPathView)}
    />
    <Route
      path="/learning/:contentType/:slug?"
      component={userIsAuthenticated(LearningPathView)}
    />
    <Route path="/practice">
      <Switch>
        <Route
          path="/practice/:practice_type?"
          component={userIsAuthenticated(PracticeProblemsView)}
        />
      </Switch>
    </Route>
    <Redirect from="/paths" to="/dashboard" />
    <RedirectWithParams from="/profiles/:username?" to="/profile/:username?" />
    <Route path="/profile">
      <Switch>
        <Route
          exact
          path="/profile"
          component={userIsAuthenticated(ProfileView)}
        />
        <Route path="/profile/:username?" component={ProfileView} />
      </Switch>
    </Route>

    <Route path="/settings">
      <Switch>
        <Route
          path="/settings/account"
          component={userIsAuthenticated(AccountView)}
        />
        <Route
          path="/settings/subscription"
          component={userIsAuthenticated(CurrentPlanView)}
        />
        <Route
          path="/settings/share"
          component={userIsAuthenticated(ReferralProgramView)}
        />
      </Switch>
    </Route>

    <Route path="/account">
      <Switch>
        <Redirect from="/account/current-plan" to="/settings/subscription" />
        <Redirect path="/account/settings" to="/settings/account" />
        <Redirect path="/account/management" to="/settings/account" />
        <Redirect from="/account/share" to="/settings/share" />
        <Redirect from="*" to="/account/current-plan" />
      </Switch>
    </Route>
    <Redirect from="/reward-badges" to="/dashboard" />
    <Route path="/404" component={page_not_found} />
    <Route component={page_not_found} />
  </Switch>
);

export default NonLoadingRoutes;
