import DOMPurify from "dompurify";
import some from "lodash/some";

// Define whitelisted tags for manual sanitation
const WHITELISTED_TAGS = ["script", "video", "iframe"];
const WHITELISTED_ATTRS = [
  "target",
  "data",
  "src",
  "allowFullScreen",
  "frameBorder",
];
const ALLOWED_SCRIPT_TYPES = ["math/tex"];
const ALLOWED_DATA_TAGS = ["video", "iframe"];

/**
 * Sanitizes js script removing disallowed script forms
 * @param {*} scriptNode  script node to be sanitized
 */
const sanitizeScript = scriptNode => {
  const script = scriptNode.textContent;

  // Check for allowed script type
  const allowedScriptType = ALLOWED_SCRIPT_TYPES.includes(
    scriptNode.getAttribute("type"),
  );

  // Only allow if script provided and of allowed type
  if (!script || !allowedScriptType)
    return scriptNode.parentNode.removeChild(scriptNode);
};

/**
 * Hook function for sanitizing whitelisted elements
 * NOTE: All whitelisted tags should be manually verified
 * @param {*} node
 * @param {*} data
 */
const uponSanitizeElementHook = (node, data) => {
  // Process sanitization specific to element type
  if (data.tagName === "script") return sanitizeScript(node);
};

// Add hook for white-listed element sanitation
DOMPurify.addHook("uponSanitizeElement", uponSanitizeElementHook);

// Add hook for removing media-only attrs
DOMPurify.addHook("afterSanitizeAttributes", node => {
  if (node.hasAttribute("allowFullScreen")) {
    if (
      !some(
        ALLOWED_DATA_TAGS,
        tagName => tagName === node.tagName.toLowerCase(),
      )
    )
      node.removeAttribute("allowFullScreen");
  }
  if (node.hasAttribute("frameBorder")) {
    if (
      !some(
        ALLOWED_DATA_TAGS,
        tagName => tagName === node.tagName.toLowerCase(),
      )
    )
      node.removeAttribute("frameBorder");
  }
});

/**
 * Sanitizes html via DOMPurify with pre-specified config and hooks
 * @param {string} html     raw html to be sanitized
 * @param {object} options  flags to extend configuration
 * @returns {string}        sanitized html
 */
const sanitizeHtml = (html, options = {}) => {
  if (options?.iterableContent) {
    return DOMPurify.sanitize(html, {
      ADD_TAGS: [...WHITELISTED_TAGS, "iframe"],
      ADD_ATTR: WHITELISTED_ATTRS,
    });
  }

  let htmlCopy = html;
  if (options?.plainText) {
    htmlCopy = html
      .split("\n")
      .map(line => `<p>${line.replaceAll(" ", "&nbsp;")}</p>`)
      .join("");
  }

  return DOMPurify.sanitize(htmlCopy, {
    ADD_TAGS: WHITELISTED_TAGS,
    ADD_ATTR: WHITELISTED_ATTRS,
  });
};

export default sanitizeHtml;
