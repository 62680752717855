import Immutable from "seamless-immutable";
import { createSelector } from "reselect";
import {
  make_simple_selectors,
  make_simple_reducer,
  make_reducer_n_actions,
} from "redux_helpers";
import defaultTo from "lodash/defaultTo";
import reduce from "lodash/reduce";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import get from "lodash/get";

import {
  cleanLesson,
  clean_upgrade_suggestion,
} from "redux/helpers/clean_lessons";
// -------
// Initial State
// --------

const initial_state = {
  lessonInfo: {
    ...cleanLesson({}),
    overall_type: "",
  },
  downloads: [],
  lessonNext: {
    id: "",
    title: "",
    sequence: "",
    course_sequence: null,
    allowed: false,
    upgrade_suggestion: "",
    screen: null,
    type: "mission",
    alternate: {
      id: "",
      title: "",
      sequence: "",
    },
    assessmentSkills: [],
    assessmentScreens: 0,
  },
  skipToNextMission: {
    id: "",
    title: "",
    sequence: "",
    course_sequence: null,
    allowed: false,
    upgrade_suggestion: "",
    screen: null,
    type: "mission",
    alternate: {
      id: "",
      title: "",
      sequence: "",
    },
    assessmentSkills: [],
    assessmentScreens: 0,
  },
  assessmentInfo: {
    questionIds: [],
    questionSkills: [],
    questionResults: [],
    correctQuestions: 0,
    totalQuestions: 0,
    skillNames: [],
    skillMastery: {},
    isFirstComplete: false,
  },
  lessonNotFound: false,
  lessonSEOPaywall: false,
};

// -------
// Selectors
// --------
const BASE = "lessonInfo";
export { BASE as BASE_SELECTOR_PATH };

export const selectors = make_simple_selectors(initial_state, BASE);

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "LESSON_INFO/";

const public_handlers = {
  reset: _state => Immutable(initial_state),
};

export const LESSON_RESET_ACTION_NAME = `${action_types_prefix}reset`;

const private_handlers = {
  setLessonNotFound: state => state.merge({ lessonNotFound: true }),
  setLessonSEOPaywall: state => state.merge({ lessonSEOPaywall: true }),
  setLesson: (
    state,
    { payload: { mission, screens = [], lesson_metadata } },
  ) => {
    const hasTakeaways = screens[screens.length - 1]?.type === "takeaways";
    const cleanedLesson = cleanLesson(mission);
    const hasReview =
      hasTakeaways && screens[screens.length - 2].type === "text";

    return state.merge({
      lessonInfo: {
        ...cleanedLesson,
        overall_type: reduce(
          screens,
          (result, { type }) => {
            if (type === "command_line") return "cli";
            return result;
          },
          defaultTo(mission.type, ""),
        ),
        metadata: lesson_metadata,
        has_takeaways: hasTakeaways,
        screen_count:
          cleanedLesson.screen_count && hasReview
            ? cleanedLesson.screen_count - 1
            : cleanedLesson.screen_count,
        hasReview,
      },
    });
  },
  setLessonNext: (state, { payload }) =>
    state.merge({
      lessonNext: {
        id: toString(payload.next.id),
        title: payload.next.name,
        sequence: toString(payload.next.sequence),
        course_sequence: toString(payload.next.course_sequence),
        screen: payload.next.screen ? toInteger(payload.next.screen) : null,
        allowed: payload.allowed,
        upgrade_suggestion: clean_upgrade_suggestion(payload),
        type: payload.next.type,
        alternate: {
          id: toString(payload.alternate.id),
          title: payload.alternate.name,
          sequence: toString(payload.alternate.sequence),
        },
        assessmentSkills: payload.next.assessment_skills || [],
        assessmentScreens: payload.next.assessment_screens || 0,
      },
    }),
  setSkipToNextMission: (state, { payload }) =>
    state.merge({
      skipToNextMission: {
        id: toString(payload.next.id),
        title: payload.next.name,
        sequence: toString(payload.next.sequence),
        course_sequence: toString(payload.next.course_sequence),
        screen: payload.next.screen ? toInteger(payload.next.screen) : null,
        allowed: payload.allowed,
        upgrade_suggestion: clean_upgrade_suggestion(payload),
        type: payload.next.type,
        alternate: {
          id: toString(payload.alternate.id),
          title: payload.alternate.name,
          sequence: toString(payload.alternate.sequence),
        },
        assessmentSkills: payload.next.assessment_skills || [],
        assessmentScreens: payload.next.assessment_screens || 0,
      },
    }),
  setAssessment: (state, { payload }) =>
    state.merge({
      assessmentInfo: {
        questionIds: get(payload, ["question_ids"], []),
        questionSkills: eval(get(payload, ["question_skills"], [])),
        correctQuestions: get(payload, ["correct_questions"], 0),
        totalQuestions: get(payload, ["total_questions"], 0),
        skillNames: get(payload, ["skill_names"], []),
        skillMastery: payload.skill_mastery
          ? JSON.parse(payload.skill_mastery)
          : {},
        questionResults: get(payload, ["question_results"], []).map(result => ({
          questionId: result.question_id,
          correct: result.correct,
          givenAnswer: result.given_answer,
          skillNames: result.skill_names,
          skillMastery: JSON.parse(result.skill_mastery),
          skillFeedback: JSON.parse(result.skill_improvement_feedback),
          answerFeedback: result.answer_checker_feedback,
        })),
        isFirstComplete: payload.is_first_complete,
      },
    }),
  set_downloads: make_simple_reducer("downloads"),
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});
export default reducer;
