import Immutable from "seamless-immutable";
import defaultTo from "lodash/defaultTo";
import find from "lodash/find";
import findKey from "lodash/findKey";
import forEach from "lodash/forEach";
import findLastIndex from "lodash/findLastIndex";
import get from "lodash/get";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import pick from "lodash/pick";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import toString from "lodash/toString";
import { createSelector } from "reselect";
import { make_simple_selectors, make_reducer_n_actions } from "redux_helpers";

// -------
// Initial State
// --------
// current_changed_by options: 'scrolling', 'direct', 'next'

const initial_state = {
  steps_completed: [],
  steps_info: [],
  steps_user: [],
  steps_output: [],
  steps_grader: [],
  steps_deployment: [],
  answer_checking: {
    step_index: 0,
    start_time: null,
    no_check: false,
    is_running: false,
    is_running_only: false,
    was_aborted: false,
    key: null,
  },
  current: 0,
  openEndOfLesson: false,
  assessmentResultsStep: false,
  tries_before_answer: 0,
};

if (__DEV__) {
  initial_state.tries_before_answer = 0;
}

const steps_initials = {
  complete: false,
  info: {
    id: "",
    sequence: "",
    title: "",
    body: "",
    is_experiment: false,
    answer: "",
    help: "",
    instructions: "",
    type: "",
    takeaways_pdf_url: "",
    editor_language: "",
    filename: "script.py",
    solution_url: "",
    community_link: "https://community.dataquest.io",
    split_test_label: "",
    index: "",
  },
  user: {
    code: "",
    run_count: 0,
    show_answer: false,
    show_help: false,
  },
  output: {
    run_failed: false,
    has_result: false,
    success: false,
    hints: [],
    errors: "",
    results: "",
    vars: [],
    plots: [],
    checks: {},
  },
  grader: {},
  deployment: {},
};
// -------
// Selectors
// --------
const BASE = "steps_info";
export { BASE as BASE_SELECTOR_PATH };

const simple_selectors = make_simple_selectors(initial_state, BASE);

import { selectors as progress_selectors } from "../progress/progress_simple";

const step_list = createSelector(
  state => progress_selectors.lessonStepsCompleted(state), // mockable this way
  state => progress_selectors.lessonStepsSkipped(state), // mockable this way
  simple_selectors.steps_info,
  (completes, skipped, infos) =>
    infos.map((s, index) => ({
      completed: get(completes, index, false),
      skipped: get(skipped, index, false),
      title: s.title,
      just_text: s.type === "text" || s.type === "takeaways",
      type: s.type,
    })),
);

const last_code_step_index = createSelector(
  simple_selectors.steps_info,
  steps => findLastIndex(steps, (s, _i) => s.type !== "text"),
);

const current_step_info = createSelector(
  simple_selectors.steps_info,
  simple_selectors.current,
  (steps, current_step) =>
    get(steps, current_step, Immutable(steps_initials.info)),
);
const current_step_output = createSelector(
  simple_selectors.steps_output,
  simple_selectors.current,
  simple_selectors.steps_info,
  (outputs, current_step, infos) => ({
    ...get(outputs, current_step, Immutable(steps_initials.output)),
    results_is_table:
      get(infos, [current_step, "editor_language"], "").toLowerCase() === "sql",
  }),
);
const current_step_deployment = createSelector(
  simple_selectors.steps_deployment,
  simple_selectors.current,
  (steps, current_step) =>
    get(steps, current_step, Immutable(steps_initials.deployment)),
);

const current_step_grader = createSelector(
  simple_selectors.steps_grader,
  simple_selectors.current,
  (steps, current_step) =>
    get(steps, current_step, Immutable(steps_initials.grader)),
);
const show_help = createSelector(
  simple_selectors.steps_user,
  simple_selectors.current,
  (steps, current_step) => get(steps, [current_step, "show_help"], false),
);
const show_answer = createSelector(
  simple_selectors.steps_user,
  simple_selectors.current,
  (steps, current_step) => get(steps, [current_step, "show_answer"], false),
);
const current_code = createSelector(
  simple_selectors.steps_user,
  simple_selectors.current,
  (steps, current_step) => get(steps, [current_step, "code"], ""),
);
const run_count_selector = createSelector(
  simple_selectors.steps_user,
  simple_selectors.current,
  (steps, current_step) => get(steps, [current_step, "run_count"], 0),
);

const current_step_index = createSelector(
  simple_selectors.steps_info,
  simple_selectors.current,
  (steps, current) => Math.max(Math.min(current, steps.length - 1), 0),
);

export const selectors = {
  ...pick(simple_selectors, [
    "steps_info",
    "steps_user",
    "steps_output",
    "steps_grader",
    "answer_checking",
    "tries_before_answer",
    "openEndOfLesson",
    "assessmentResultsStep",
  ]),
  current_step_index,
  // visible_steps,
  current_step_info,
  current_step_output,
  current_step_deployment,
  current_step_grader,
  show_answer,
  show_help,
  current_code,
  run_count: run_count_selector,
  step_list,
  last_code_step_index,
};

// ------------------------------------
// Reducer Cleaners
// ------------------------------------

export const new_output = (data, has_result) => ({
  ...steps_initials.output,
  ...results_to_output(
    {
      result: data.output,
      variables: data.vars,
      passed_check: data.check,
      hints: data.hints,
      checks: data.checks,
    },
    has_result,
  ),
});

export function results_to_output(results = {}, has_result) {
  const {
    result = {},
    variables,
    passed_check = false,
    hints = [],
    checks,
  } = results;
  return {
    has_result,
    success: passed_check,
    hints: map(hints, "text"),
    row_count: parseInt(get(result, "stdout_row_count", null), 10),
    results: get(result, "stdout", ""),
    errors: get(result, "stderr", ""),
    vars: setup_variables(variables),
    plots: result.images || [],
    checks: cleanup_checks(checks, result.images),
  };
}
export function setup_variables(variables) {
  if (!variables || Object.keys(variables).length === 0) return [];

  return sortBy(
    map(
      variables,
      (info, name) => ({
        name: info.name || name,
        ...clean_variable(info),
      }),
      "name",
    ),
  );
}
function clean_variable(info) {
  if (info == null) {
    return {
      type: "",
      base_class: "",
      value: "",
      is_html: false,
    };
  }

  let value;
  if (info.r_dataframe) {
    value = info.string_form.join(" ");
  } else if (isArray(info.string_form)) {
    value = `["${info.string_form.join('","')}"]`;
  } else {
    value = info.string_form;
  }
  return {
    type: info.type_name,
    base_class: info.base_class,
    value,
    is_html: info.html_output || false,
  };
}

function cleanup_checks(raw_checks = {}, output_plots) {
  const checks = {};
  if (isEmpty(raw_checks)) return checks;
  if (raw_checks.output.enabled) {
    checks.output = {
      pass: raw_checks.output.pass,
      value: raw_checks.output.expected,
    };
  } else {
    checks.output = { pass: null };
  }
  checks.vars = !raw_checks.vars.enabled
    ? {}
    : reduce(
        raw_checks.vars.breakdown,
        (all, info, name) => ({
          ...all,
          [name]: {
            ...clean_variable(info.expected_inspection),
            pass: info.pass,
          },
        }),
        {},
      );
  if (raw_checks.plots.enabled && raw_checks.plots.pass !== true) {
    checks.plots = map(output_plots, img => {
      const p_check = find(raw_checks.plots.breakdown, { actual_image: img });
      if (p_check == null) {
        return { pass: null };
      }
      const answer = {
        pass: p_check.pass,
      };
      if (answer.pass === false) {
        answer.value = p_check.expected_image;
      }
      return answer;
    });
    forEach(raw_checks.plots.breakdown, p_check => {
      if (p_check.expected_image == null) return; // nothing expect?
      if (!includes(output_plots, p_check.actual_image)) {
        checks.plots.push({
          pass: false,
          value: p_check.expected_image,
        });
      }
    });
  } else {
    checks.plots = [];
  }

  return checks;
}

// ------------------------------------
// Reducer and Actions
// ------------------------------------
const action_types_prefix = "steps_info/";

const other_handlers = {
  "LESSON_INFO/reset": () => Immutable(initial_state),
};

const private_handlers = {
  set_abort: state =>
    state.merge(
      {
        answer_checking: {
          is_running_only: false,
          is_running: false,
          was_aborted: true,
          code_run_info: null,
        },
      },
      { deep: true },
    ),
  start_answer_checking: (state, { payload }) =>
    state.set("answer_checking", {
      ...payload,
      is_running: true,
      is_running_only: payload.no_check,
      was_aborted: false,
    }),
  fail_checking: state => {
    const newState = state.merge(
      {
        answer_checking: {
          is_running: false,
          is_running_only: false,
          code_run_info: null,
        },
      },
      { deep: true },
    );
    return newState.setIn(
      ["steps_output", state.answer_checking.step_index, "run_failed"],
      true,
    );
  },
  reset_answer_checking: state =>
    state.set("answer_checking", initial_state.answer_checking),
  checking_results: (state, { payload, key }) => {
    if (payload.state === "RUNNING") {
      return state.merge({
        answer_checking: {
          ...state.answer_checking,
          code_run_info: {
            code_run_id: payload.code_run_id,
            runner_name: payload.runner_name,
            session_id: payload.session_id,
          },
        },
      });
    }

    if (!state.answer_checking.is_running) return state;
    if (state.answer_checking.key !== key) return state;

    const { step_index, no_check } = state.answer_checking;
    const { run_count } = state.steps_user[step_index];
    let newState = state.merge(
      {
        answer_checking: {
          is_running: false,
          is_running_only: false,
          code_run_info: null,
        },
      },
      { deep: true },
    );
    newState = newState.setIn(
      ["steps_user", step_index, "run_count"],
      run_count + 1,
    );

    if (payload.newState === "FAILURE") {
      return newState.setIn(
        ["steps_output", newState.answer_checking.step_index, "run_failed"],
        true,
      );
    }

    newState = newState.setIn(
      ["steps_grader", step_index],
      payload.checks?.grader,
    );

    // cleanup output
    return newState.setIn(
      ["steps_output", step_index],
      new_output(payload.output, !no_check),
    );
  },
  checking_running: (state, { payload, key }) => {
    if (payload.state === "RUNNING") {
      return state.merge({
        answer_checking: {
          ...state.answer_checking,
          code_run_info: {
            code_run_id: payload.code_run_id,
            runner_name: payload.runner_name,
            session_id: payload.session_id,
          },
        },
      });
    }

    if (!state.answer_checking.is_running_only) return state;
    if (state.answer_checking.key !== key) return state;
    const screenInfo = state.steps_info[state.current];
    const { is_experiment } = screenInfo;

    const { step_index, no_check } = state.answer_checking;
    const { run_count } = state.steps_user[step_index];
    let newState = state.merge(
      {
        answer_checking: {
          is_running: false,
          is_running_only: false,
        },
      },
      { deep: true },
    );
    newState = newState.setIn(
      ["steps_user", step_index, "run_count"],
      run_count + 1,
    );
    if (payload.checks && payload.checks.grader)
      newState = newState.setIn(
        ["steps_grader", step_index],
        payload.checks.grader,
      );

    if (payload.connection_info)
      return newState.setIn(
        ["steps_deployment", step_index],
        payload.connection_info,
      );

    const payloadOutput = payload.output;

    // set output to success if user is on experimental screen
    // keeping the default value otherwise (it's in most cases just undefined)
    if (is_experiment) payloadOutput.check = true;
    // cleanup output
    return newState.setIn(
      ["steps_output", step_index],
      new_output(payloadOutput, !no_check),
    );
  },
  set_code: (state, { payload: { step_index, code } }) =>
    state.setIn(["steps_user", step_index, "code"], code),

  reset_step: (state, { payload }) => {
    const step_index = findKey(state.steps_info, {
      id: toString(payload.screen),
    });
    if (step_index == null) return state; // not there

    const data = payload.output;
    let newState = state.setIn(["steps_user", step_index, "code"], data.code);

    newState = newState.setIn(["steps_grader", step_index], {});
    return newState.setIn(
      ["steps_output", step_index],
      new_output(data, false),
    );
  },
  change_to_step: (state, { payload }) =>
    state.merge({
      current: payload,
      openEndOfLesson: isEndOfLesson(state.steps_info, payload),
      assessmentResultsStep: isAssessmentResults(state.steps_info, payload),
    }),
};
const isEndOfLesson = ({ length }, current_step) =>
  length > 0 && current_step >= length;

const isAssessmentResults = ({ length }, current_step) =>
  length > 0 && current_step === length;

const public_handlers = {
  setup: (state, { payload }) => {
    const steps_info = payload.steps.map(({ info }) => ({
      ...steps_initials.info,
      ...info,
      editor_language: defaultTo(info.editor_language, ""),
    }));
    const steps_user = payload.steps.map(({ user = {} }) => ({
      ...steps_initials.user,
      ...user,
    }));
    const steps_output = payload.steps.map(({ output = {} }) => ({
      ...steps_initials.output,
      ...output,
    }));
    const steps_deployment = payload.steps.map(({ deployment = {} }) => ({
      ...steps_initials.deployment,
      ...deployment,
    }));
    const steps_grader = payload.steps.map(({ grader = {} }) => ({
      ...steps_initials.grader,
      ...grader,
    }));
    const current = Number(payload.current);
    return state.merge({
      steps_info,
      steps_user,
      steps_output,
      steps_grader,
      steps_deployment,
      current,
      current_changed_by: initial_state.current_changed_by,
      openEndOfLesson: isEndOfLesson(steps_info, current),
      assessmentResultsStep: isAssessmentResults(steps_info, current),
    });
  },
  resetUserCode: state => {
    const newStepsUser = [...state.steps_user].map(stepUser => ({
      ...stepUser,
      code: "",
    }));
    return state.setIn(["steps_user"], newStepsUser);
  },
  resetOutput: state => {
    const newStepsOutput = [...state.steps_output].map(stepOutput => ({
      ...steps_initials.output,
    }));
    return state.setIn(["steps_output"], newStepsOutput);
  },
  setShowAnswer: (state, { payload }) => {
    const newState = state.setIn(
      ["steps_user", state.current, "show_answer"],
      payload,
    );
    if (!payload) return newState;
    return newState.setIn(
      ["steps_user", newState.current, "show_help"],
      !payload,
    );
  },
  setShowHelp: (state, { payload }) => {
    const newState = state.setIn(
      ["steps_user", state.current, "show_help"],
      payload,
    );
    if (!payload) return newState;
    return newState.setIn(
      ["steps_user", newState.current, "show_answer"],
      !payload,
    );
  },
  set_output: (state, { payload: { step_index, output } }) => {
    const newOutput = { ...steps_initials.output, ...output };
    const { run_count } = state.steps_user[step_index];

    const newState = state.setIn(["steps_output", step_index], newOutput);
    return newState.setIn(
      ["steps_user", step_index, "run_count"],
      run_count + 1,
    );
  },
};

export const {
  reducer,
  private_actions,
  actions,
  ACTION_TYPES,
} = make_reducer_n_actions({
  other_handlers,
  public_handlers,
  private_handlers,
  action_types_prefix,
  initial_state,
  Immutable,
});

export default reducer;
