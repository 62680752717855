// @flow
import type { Node } from "react";
import React from "react";
import cn from "classnames";
import { LEVEL_NAMES } from "helpers/constants";

type Props = {
  /**
   * Level of the skill (0 - Beginner, 1 - Intermediate, 2 - Advanced)
   */
  level: number,
  white?: boolean,
};

const DqSkillLevel = ({ level = 0, white = false }: Props): Node => (
  <span
    className={cn({
      "dq-text-purple": !white,
      "dq-text-white": white,
    })}
  >
    {LEVEL_NAMES[level]}
  </span>
);

DqSkillLevel.defaultProps = {
  white: false,
};

export default DqSkillLevel;
