import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import { withRouter } from "react-router";
import { validateSignUp, validateSignUpIframe } from "utils/validations";
import { goToRoute } from "helpers/react-router";

import {
  selectors as user_selectors,
  actions as user_actions,
} from "redux/modules/user_info";

import EventsTracker from "helpers/events_tracker";
import { window_location } from "helpers/window_location";
import { ONBOARDING_SHOWN_BUT_NOT_COMPLETED } from "helpers/constants";

import withJumplinks from "components/auth/Jumplinks/AuthJumplinks";
import { checkRecaptcha, skipRecaptcha } from "helpers/checkRecaptcha";

dayjs.extend(timezone);

export const selectors = user_selectors;
export const actions = {
  ...user_actions,
};

const mapStateToProps = createStructuredSelector(selectors);

const ANALYTICS_SIGNUP = "accounts-signup-success-frontend";

const signUpAccountExistsErr = "Incorrect password";
const signUpAccountExistsErrMsg = "Email already in use.";

function withSignUp(WrappedComponent) {
  class SignUp extends React.PureComponent {
    static propTypes = {
      // from redux
      signup_errors: PropTypes.object.isRequired,

      signup: PropTypes.func.isRequired,
      setReferral: PropTypes.func.isRequired,
      get_social_token_signup: PropTypes.func.isRequired,
      set_signup_error: PropTypes.func.isRequired,
      refresh_user: PropTypes.func.isRequired,
      setSkipOnboarding: PropTypes.func.isRequired,
      storeAuthData: PropTypes.func.isRequired,
      setDisplayCourseContext: PropTypes.func.isRequired,

      displayMFARequest: PropTypes.bool.isRequired,
      is_logged_in: PropTypes.bool.isRequired,
      is_new_user: PropTypes.bool.isRequired,
      starting_page: PropTypes.string.isRequired,
      onboarding_status: PropTypes.string.isRequired,

      // passed in
      signupOnly: PropTypes.bool,
      signupReferralCode: PropTypes.string,
      signupReferralEmail: PropTypes.string,
      isReferral: PropTypes.bool,

      // from with jumplinks component
      targetUrls: PropTypes.shape({
        targetPath: PropTypes.string,
        targetVersion: PropTypes.string,
        targetCourse: PropTypes.string,
        targetLesson: PropTypes.string,
        targetUrl: PropTypes.string,
        postSignupUrl: PropTypes.string,
        postLoginUrl: PropTypes.string,
      }),
      targetSlugs: PropTypes.shape({
        path: PropTypes.string,
        course: PropTypes.string,
        lesson: PropTypes.string,
      }),
      skipSource: PropTypes.string,

      shouldSkipOnboardingForNow: PropTypes.bool,

      // router
      history: PropTypes.shape({
        push: PropTypes.func,
      }).isRequired,
      location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        search: PropTypes.string.isRequired,
      }).isRequired,
    };

    static defaultProps = {
      isReferral: false,
      signupOnly: false,
      signupReferralCode: "",
      signupReferralEmail: "",
      skipSource: "",

      targetUrls: {
        targetPath: "",
        targetCourse: "",
        targetLesson: "",
        targetUrl: "",
        postLoginUrl: "",
        postSignupUrl: "",
      },
      shouldSkipOnboardingForNow: false,
      targetSlugs: {
        path: "",
        course: "",
        lesson: "",
      },
    };

    constructor() {
      super();
      this.state = {
        submitting: false,
        formValues: {
          name: __DEV__ ? "test_user" : "",
          email: __DEV__
            ? `test_user${`${Date.now()}`.slice(-4)}@dataquest.io`
            : "",
          password: __DEV__ ? `test_user${`${Date.now()}`.slice(-4)}` : "",
        },
        mfaToken: "",
        loadReCAPTCHA: false,
        recaptchaRef: React.createRef(),
      };
    }

    componentDidUpdate(prevProps) {
      const { recaptchaRef } = this.state;

      // A workaround for component re-mounting
      if (this.props.displayMFARequest && !prevProps.displayMFARequest) {
        this.props.storeAuthData({
          email: this.state.formValues.email,
          password: this.state.formValues.password,
        });
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ submitting: false });
      }

      if (this.hasAnError(this.props)) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ submitting: false });
        if (checkRecaptcha(recaptchaRef)) {
          recaptchaRef.current.reset();
        }
      }

      if (
        !window_location.isIframe() &&
        this.props.is_logged_in &&
        !this.props.is_new_user &&
        (this.props.location.pathname.indexOf("/signup") !== -1 ||
          this.props.location.pathname.indexOf("/login") !== -1)
      ) {
        this.redirectLoggedInUser();
      }
    }

    redirectLoggedInUser = () => {
      const {
        targetPath,
        targetCourse,
        targetLesson,
        postLoginUrl,
      } = this.props.targetUrls;
      const { onboarding_status, skipSource } = this.props;

      const onboardingSkipReason = targetPath || targetCourse || targetLesson;
      const nextUrl = onboardingSkipReason
        ? this.props.starting_page || postLoginUrl
        : postLoginUrl;

      const postLoginGoto = () => goToRoute(this.props.history, nextUrl);

      // If onboarding hasn't been completed and has a skip reason, skip it
      if (
        onboarding_status === ONBOARDING_SHOWN_BUT_NOT_COMPLETED &&
        onboardingSkipReason
      ) {
        this.props
          .setSkipOnboarding(
            onboardingSkipReason,
            window_location.isIframe(),
            skipSource,
          )
          .then(() => postLoginGoto());
      } else postLoginGoto();
    };

    updateMfaToken = event => {
      this.setState({ mfaToken: event.target.value });
    };

    handleOnChange = event => {
      const { formValues, loadReCAPTCHA } = this.state;

      if (!loadReCAPTCHA) {
        this.setState({ loadReCAPTCHA: true });
      }

      this.setState({
        formValues: {
          ...formValues,
          [event.target.name]: event.target.value,
        },
      });
    };

    handleOnSubmit = (event, callback) => {
      const { recaptchaRef } = this.state;

      if (event) event.preventDefault();
      if (this.state.submitting) {
        return;
      }

      const validation =
        window_location.isIframe() || this.props.isReferral
          ? validateSignUpIframe({ ...this.state.formValues })
          : validateSignUp({ ...this.state.formValues });
      let validationErrors = "";

      if (!isEmpty(validation.error)) {
        validation.error.details.forEach(item => {
          validationErrors = `${validationErrors} ${item.message}`;
        });

        this.reportFailure(validationErrors);

        if (checkRecaptcha(recaptchaRef)) {
          recaptchaRef.current.reset();
        }

        return;
      }

      if (skipRecaptcha(this.props.displayMFARequest)) {
        // ignore recaptcha
        this.setState({ submitting: true });
        this.makeSubmitCall(null, callback);
      } else {
        try {
          this.callback = callback;
          // initiate recaptcha validation
          recaptchaRef.current.execute();
        } catch (e) {
          console.error("error loading recaptcha");
        }
      }
    };

    /**
     * recaptcha data callback
     * executed when user submits a successful response
     * g-recaptcha-response token is passed in as an argument
     * @param {string} recaptchaToken
     */
    recaptchaHandleOnChange = recaptchaToken => {
      this.setState({ submitting: true });
      this.makeSubmitCall(recaptchaToken, this.callback);
    };

    /**
     * recaptcha error callback
     * executed when reCAPTCHA encounters an error (usually network connectivity)
     * and cannot continue until connectivity is restored
     */
    recaptchaHandleOnErrored = () => {
      this.reportFailure(
        "There was a network error. Check your connection and try again.",
      );
    };

    /**
     * Actions to be peformed after a successful signup
     *
     * - Go to post signup url on signup starting page.
     * - Go to post login url on forwarded login
     *
     */
    getPostSignUpURL = (data, skipOnboarding) => {
      const { postSignupUrl, postLoginUrl } = this.props.targetUrls;

      // Redirect to post login url if logged in instead of sign up
      if (data && data.forwarded_to_login && !skipOnboarding)
        return postLoginUrl;

      // If onboarding should not be displayed, use starting page or post signup url
      return data?.profile?.starting_page || postSignupUrl;
    };

    reportSignupEvent = (signupType: string) => {
      // on FE status is always success (for now?), but
      // BE will send events with failed status also. Adding
      // status here for symmetry.
      EventsTracker.track(
        "account_signup",
        {
          status: "success",
          signup_type: signupType,
          source_url: this.props.location.pathname,
        },
        {
          version: 3,
        },
      );

      // Deprecated / old event (keeping until data migration
      // is complete)
      const serviceName =
        signupType === "email" ? "emailAndPassword" : signupType;
      EventsTracker.track(ANALYTICS_SIGNUP, {
        service: serviceName,
      });
    };

    makeSubmitCall = (recaptchaToken = "", callback) => {
      // we need to execute getTargetUrl() and bind the value here, before we proceed with executing Promises
      // as somewhere in the flow redirection/URL change MIGHT happen -- and when executing in .then
      // we won't get the original target-url
      // TODO: general auth flow requires deep refactoring -- it's really difficult to properly understand all scenarios now

      const {
        signupReferralCode,
        signupReferralEmail,
        displayMFARequest,
      } = this.props;
      const {
        targetPath,
        targetVersion,
        targetCourse,
        targetLesson,
        targetUrl,
      } = this.props.targetUrls;

      let skipOnboarding = null;
      if (targetPath || targetCourse || targetLesson)
        skipOnboarding = {
          path: targetPath,
          version: targetVersion,
          course: targetCourse,
          lesson: targetLesson,
        };

      // If user is redirected to a course, display course context modal
      if (targetCourse) this.props.setDisplayCourseContext(targetCourse);
      this.props
        .signup({
          ...this.getCredentials(),
          referral_code: signupReferralCode,
          referral_email: signupReferralEmail,
          timezone: dayjs.tz.guess(),
          skip_onboarding: skipOnboarding,
          recaptchaToken,
        })
        .then(data => {
          if (callback) {
            callback(isEmpty(this.props.signup_errors));
            return;
          }

          if (displayMFARequest) return;

          if (data && !data.forwarded_to_login) {
            this.reportSignupEvent("email");
          }

          return this.getPostSignUpURL(data, skipOnboarding);
        })
        .then(nextUrl => {
          if (callback) return;
          if (displayMFARequest) {
            this.setState({ submitting: false });
            return;
          }

          if (window_location.isIframe()) {
            window_location.redirect_grandparent(nextUrl);
          } else if (isEmpty(this.props.signup_errors)) {
            if (targetPath || targetLesson || targetCourse || targetUrl) {
              goToRoute(this.props.history, nextUrl);
            } else {
              goToRoute(this.props.history, nextUrl, {
                source: "signup_redirect",
              });
            }
          }
        });
    };

    socialSignupSuccess = (socialService, isNew) => {
      this.props.get_social_token_signup().then(() => {
        this.props
          .refresh_user()
          .then(() => {
            this.setReferral();
            let nextUrl = this.props.targetUrls.postLoginUrl;
            if (isNew) {
              this.reportSignupEvent(socialService);
              nextUrl = this.getPostSignUpURL(null);
            }
            return nextUrl;
          })
          .then(nextUrl => {
            if (window_location.isIframe()) {
              window_location.redirect_grandparent(nextUrl);
              return;
            }
            goToRoute(this.props.history, nextUrl);
          });
      });
    };

    socialSignupFailure = (service, message) => {
      this.reportFailure(message);
    };

    reportFailure = message => {
      this.props.set_signup_error(message);
    };

    hasAnError = nextProps => !isEmpty(nextProps.signup_errors);

    getCredentials = () => {
      const { formValues, mfaToken } = this.state;
      const credentials = {
        ...pick(formValues, ["name", "email", "password"]),
      };

      if (this.props.displayMFARequest) credentials.mfa_token = mfaToken;
      return credentials;
    };

    setReferral = () => {
      if (this.props.signupReferralCode) {
        this.props.setReferral(
          this.props.signupReferralCode,
          this.props.signupReferralEmail,
        );
      }
    };

    renderGeneralErrors = () => {
      const errors = mapValues(this.props.signup_errors, e => {
        if (e && e.includes(signUpAccountExistsErr))
          e = signUpAccountExistsErrMsg;
        return e;
      });

      if (isEmpty(errors)) {
        return null;
      }

      return (
        <div
          className="dq-general-errors dq-text-red dq-bg-red-100 dq-border-red dq-p-4 dq-block dq-input-block dq-animate-appear"
          data-test-selector="error-notification"
        >
          {map(errors, (e, key) => (
            <div key={key} className="dq-text-center">
              {e}
            </div>
          ))}
        </div>
      );
    };

    render() {
      return (
        <WrappedComponent
          data={this.state}
          handleOnChange={this.handleOnChange}
          handleOnSubmit={this.handleOnSubmit}
          socialSignupSuccess={this.socialSignupSuccess}
          socialSignupFailure={this.socialSignupFailure}
          renderGeneralErrors={this.renderGeneralErrors}
          recaptchaHandleOnChange={this.recaptchaHandleOnChange}
          recaptchaHandleOnErrored={this.recaptchaHandleOnErrored}
          updateMfaToken={this.updateMfaToken}
          loadReCAPTCHA={this.state.loadReCAPTCHA}
          {...this.props}
        />
      );
    }
  }

  return withJumplinks(
    withRouter(
      connect(
        mapStateToProps,
        actions,
      )(SignUp),
    ),
  );
}

export default withSignUp;
