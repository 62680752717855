import { actions as user_actions } from "redux/modules/user_info";
import { actions as progressActions } from "redux/modules/progress";

import { dq_graphql } from "redux/utils/dq_fetch";
import EventsTracker from "helpers/events_tracker";
import { private_actions, selectors } from "./team_info_simple";

export const get_team_info = (
  team_id,
  includeProgress = false,
  skipMembers = false,
) => (dispatch, getState) => {
  const promises = []; // for testing
  promises.push(fetch_team(team_id)(dispatch, getState));
  if (!skipMembers)
    promises.push(fetch_members(team_id, includeProgress)(dispatch, getState));
  return Promise.all(promises)
    .then(() => dispatch(private_actions.set_loaded(true)))
    .catch(error => {
      console.warn(error);
      dispatch(private_actions.set_loaded(true));
    });
};

const fetch_team = team_id => (dispatch, _getState) => {
  const query = `{
      team(team_id:${team_id}) {
            id
            name
            owner_id
            admin_ids
            member_count
            licenses
            remaining_licenses
            settings {
                remote_app_sessions
                display_answers
                display_solutions
                content_skip
            }
            plan {
                plan_end_date
                plan_name
                plan_service
                active
                card_last4
            }
            api_key_generated
            api_key_last_updated
            api_key_last_four
            saml_idp_id
            saml_idp_url
            saml_idp_cert
            saml_begin_url
            saml_recipient_url
            saml_metadata_url
        }
    }`;
  return dq_graphql(query).then(data => {
    dispatch(private_actions.set_team_info(data.data.team));
  });
};

const fetch_members = (team_id, includeProgress = false) => (
  dispatch,
  _getState,
) => {
  const query = `{
      team(team_id:${team_id}) {
            members_access {
                user_id
                hasContentAccess: content_access
            }
            members {
                id
                username
                email
                fullname
                start_date
                updated
                ${
                  includeProgress
                    ? "current_course_progress {name, progress}, hours_current_week, hours_total"
                    : ""
                }
                password_reset_url
            }
        }
    }`;
  return dq_graphql(query).then(data => {
    dispatch(private_actions.setTeammembers(data.data.team));
  });
};

const track_leave_team = (team_id, team_name) => {
  EventsTracker.track(
    "team_leave",
    {
      team_id,
      team_name,
    },
    {
      version: 3,
    },
  );
};

export const leave_team = team_id => (dispatch, getState) => {
  const mutation = `mutation {
    leave_team(team_id:${team_id}) {
      success
    }
  }`;
  return dq_graphql(mutation).then(() => {
    const team_name = selectors.team_name(getState());
    track_leave_team(team_id, team_name);
    return dispatch(user_actions.refresh_user());
  });
};

export const updateTeammemberAccess = ({
  team_id,
  user_id,
  content_access,
}) => (dispatch, getState) => {
  const mutation = `mutation {
    update_team_member_access(team_id:${team_id},user_id:${user_id},content_access:${content_access}) {
      success, error_message
    }
  }`;
  return dq_graphql(mutation).then(() => {
    dispatch(private_actions.set_loaded(false));
    dispatch(get_team_info(team_id));
    getInvitations(team_id)(dispatch, getState);
  });
};

export const removeTeammember = ({ team_id, user_id, email }) => (
  dispatch,
  getState,
) => {
  const uidParam = user_id ? `,user_id:${user_id}` : "";
  const emailParam = email ? `,email:"${email}"` : "";
  const mutation = `mutation {remove_team_member(
    team_id:${team_id}${uidParam}${emailParam}){success, error_message}}`;
  return dq_graphql(mutation).then(data => {
    const team_name = selectors.team_name(getState());
    track_leave_team(team_id, team_name);
    return fetch_members(team_id)(dispatch, getState).then(() =>
      dispatch(private_actions.setRemoveResponse(data.data.remove_team_member)),
    );
  });
};

export const removeTeammembers = ({ team_id, emails }) => (
  dispatch,
  getState,
) => {
  const emailsParam = emails.map(e => `"${e}"`).join(",");
  const mutation = `mutation {remove_team_members(team_id:${team_id}, emails:[${emailsParam}]){
    removed, failed, error_message
  }}`;

  return dq_graphql(mutation, { resolveErr: true }).then(data => {
    const team_name = selectors.team_name(getState());
    track_leave_team(team_id, team_name);
    return Promise.all([
      fetch_members(team_id)(dispatch, getState),
      getInvitations(team_id)(dispatch, getState),
    ]).then(() =>
      dispatch(
        private_actions.setRemoveMultiResponse(data.data.remove_team_members),
      ),
    );
  });
};

export const getInvitations = team_id => (dispatch, _getState) => {
  const query = `{
        team(team_id:${team_id}) {
            invitations {
                updated
                is_admin
                content_access
                user {
                    id
                    username
                    email
                    fullname
                }
            }
          }
    }`;
  return dq_graphql(query)
    .then(data => {
      dispatch(private_actions.setInvitations(data.data.team.invitations));
    })
    .catch(err => {
      console.warn(err);
      dispatch(private_actions.setInvitationsLoaded());
    });
};

const listToGql = list => `[${list.map(p => `"${p}"`).join(",")}]`;

export const sendInvite = ({
  team_id,
  search,
  create,
  name,
  paths = [],
  courses = [],
  lessons: _lessons = [],
  resend = false,
  isAdmin = false,
  skipOnboarding = true,
  hasLicense = true,
}) => (dispatch, getState) => {
  const mutation = `mutation {send_team_invite(
        team_id:${team_id},
        search:"${search}",
        create:${create},
        resend:${resend},
        is_admin:${isAdmin},
        skip_onboarding:${skipOnboarding},
        has_license:${hasLicense},
        paths:${listToGql(paths)},
        courses:${listToGql(courses)},
        ${name ? `name:"${name}"` : ""}
    ){success, error_message}}`;

  dispatch(private_actions.setInviteResponse({}));

  return dq_graphql(mutation).then(data => {
    const { error_message } = data.data.send_team_invite;
    if (!error_message) {
      dispatch(private_actions.setInviteResponse({ sent: true }));
      getInvitations(team_id)(dispatch, getState);
    } else if (error_message === "not_found") {
      dispatch(private_actions.setInviteResponse({ not_found: true }));
    } else if (error_message === "already_member") {
      dispatch(private_actions.setInviteResponse({ already_member: true }));
    } else if (error_message) {
      dispatch(private_actions.setInviteResponse({ error_message }));
    }
  });
};

export const removeInvite = ({ user_id, team_id }) => (dispatch, getState) => {
  const mutation = `mutation {remove_team_invite(
    team_id:${team_id},
    user_id:${user_id},
  ){success, error_message}}`;

  return dq_graphql(mutation)
    .then(data =>
      getInvitations(team_id)(dispatch, getState).then(
        () => data.data.remove_team_invite,
      ),
    )
    .catch(() =>
      // Failed request in 99% cases means that invitation was removed from another session
      // The data reload should "delete" the stale invitation
      getInvitations(team_id)(dispatch, getState),
    );
};

export const sendInvites = ({ team_id, users, create }) => (
  dispatch,
  getState,
) => {
  const usersParam = users
    .map(
      u =>
        `{
          email:"${u.email}",
          name:"${u.name}",
          is_admin:${u.is_admin},
          skip_onboarding:${u.skip_onboarding},
          has_license:${u.has_license},
          ${u.paths.length > 0 ? `paths:${listToGql(u.paths)}` : ""},
          ${u.courses.length > 0 ? `courses:${listToGql(u.courses)}` : ""},
          ${u.lessons.length > 0 ? `lessons:${listToGql(u.lessons)}` : ""},
        }`,
    )
    .join(",");
  const mutation = `mutation {send_team_invites(
      team_id:${team_id},
      users:[${usersParam}],
      create:${create},
    ){invited, failed, error_message}}`;

  dispatch(private_actions.setInviteMultiResponse({}));

  return dq_graphql(mutation, { resolveErr: true }).then(data =>
    Promise.all([
      fetch_members(team_id)(dispatch, getState),
      getInvitations(team_id)(dispatch, getState),
    ]).then(() =>
      dispatch(
        private_actions.setInviteMultiResponse(data.data.send_team_invites),
      ),
    ),
  );
};

export const addToTeam = team_id => (dispatch, getState) => {
  const mutation = `mutation {join_team(team_id:${team_id}){success, error_message}}`;

  return dq_graphql(mutation)
    .then(data => {
      const { error_message } = data.data.join_team;
      if (!error_message) {
        fetch_team(team_id)(dispatch, getState).then(() => {
          const team_name = selectors.team_name(getState());
          EventsTracker.track(
            "team_join",
            {
              team_id,
              team_name,
            },
            {
              version: 3,
            },
          );
        });
        return dispatch(user_actions.refresh_user());
      }
      dispatch(private_actions.setAcceptErrorMessage(error_message));
    })
    .catch(() => dispatch(private_actions.setAcceptError()));
};

export const requestPasswordReset = (teamId, userId) => (
  dispatch,
  getState,
) => {
  if (!userId) return;
  const mutation = `mutation {generate_password_reset_url(user_id:"${userId}") { success, error_message, password_reset_url }}`;
  return dq_graphql(mutation).then(response => {
    const { data } = response;
    if (data.generate_password_reset_url.success) {
      return fetch_members(teamId)(dispatch, getState);
    }
    return Promise.reject(
      new Error(data.generate_password_reset_url.error_message),
    );
  });
};

export const fetchAssignmentsProgress = team_assignments => (
  dispatch,
  _getState,
) => {
  if (!team_assignments) return;
  dispatch(private_actions.setAssignments(team_assignments));

  team_assignments.forEach(assignment => {
    if (assignment.type === "course")
      dispatch(progressActions.getCourse(assignment));
    else if (assignment.type === "track")
      dispatch(progressActions.getPath(assignment));

    // Comment out lessons which are not fully supported yet
    // else if (assignment.type === "mission")
    //   dispatch(progressActions.getLessonProgress(assignment));
  });
};
